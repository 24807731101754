.page-headings {
  color: #fff;
  font-family: R;
  font-size: 82px;
  text-align: center;
  position: relative;
  &:after {
    content: "";
    width: 10px;
    // height: 3px;
    border: 5px solid white;
    left: 0;
    bottom: 0;
  }
}
.card {
  background-color: transparent;
  color: white;
}

.colored-text {
  color: #22b0f7;
  text-align: center;
}

.membrshp a:hover {
  text-decoration: none !important;
}

form.promo-code {
  display: flex;
}
.promo-code input {
  background: url(../../assets/img/promo_code_icon.png);
  width: 100%;
  color: #22b0f7;
  border: 1px solid #22b0f7;
  font-size: 18px;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 5.5% 52%;
  padding: 0 0 0 50px;
  border-radius: 3px;
  height: 45px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  text-transform: uppercase;
  &::placeholder {
    color: #22b0f7;
  }
  &:focus {
    outline: none;
  }
}
form.promo-code button {
  border: 1px solid #21abf0;
  background: #21abf0;
  color: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: 120px;
}
.subs_dates {
  justify-content: space-between;
  p {
    width: 50%;
    margin: 0;
    font-size: 22px !important;
    span {
      font-size: 18px;
      color: #8d8d8d;
    }
  }
}
.cancel-sub {
  margin: 30px auto 0;
}
.cancel-sub:hover {
  color: #fff !important;
}
.cancel-sub:disabled {
  opacity: 1;
  background: #ccc;
}

.promoMessage {
  font-size: 20px;
  margin: 0;
  color: green;
  font-family: "Teko-SemiBold";
}
