.containerd {
  position: relative;
  top: 0rem;
  z-index: 9;
  left: 0;
  right: 0;
  background: linear-gradient(
      180deg,
      #00000096 0%,
      #2b2b2b80 50%,
      #09090916 84%,
      #00000000 100%
    )
    0% 0% no-repeat padding-box;
}
.homesec {
  .sec1-lt {
    text-align-last: left;
  }
}
.navbar .container {
  padding: 1rem;
  max-width: 1400px;

  .nav {
    ul {
      display: flex;
      li {
        list-style: none;
        padding: 0.5rem;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
}
@font-face {
  font-family: "GTWalsheimPro-Medium";
  src: url("../assets/fonts/GTWalsheimPro-Medium.ttf");
}
.navbar {
  font-family: "GTWalsheimPro-Medium";
  position: absolute !important;
  width: 100%;
  background: linear-gradient(
      180deg,
      #00000096 0%,
      #2b2b2b80 50%,
      #09090916 84%,
      #00000000 100%
    )
    0% 0% no-repeat padding-box;
}
.navbar-nav {
  .nav-link {
    color: #fff !important;
    a {
      color: #fff;
    }
    a :active {
      color: #22b0f7 !important;
    }
  }
}
.navbar-expand-lg .navbar-collapse {
  flex-grow: 0;
  .nav-link {
    color: #fff;
  }
  .btn-register-area {
    margin-left: 110px;
    background: #22b0f7;
    color: #fff;
    border-radius: 5px;
    font-size: 20px;
    font-family: "Teko-SemiBold";
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      color: #fff;
      &:hover {
        color: #000;
      }
    }
  }
}
.dropdown-menu {
  padding: 0 !important;
}
.show.dropdown > a {
  text-decoration: none;
  color: #22b0f7 !important;
}
a.dropdown-item {
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid #ebebeb;
}
a.dropdown-item a {
  color: #000;
  font-size: 16px;
}
a.dropdown-item:hover > a {
  color: #22b0f7;
  text-decoration: none;
}
a.dropdown-item:hover {
  background-color: transparent !important;
}
a.dropdown-item:last-child {
  border-bottom: 0;
}
a.nav-link > a:hover {
  color: #22b0f7;
  text-decoration: none;
}
a.nav-link a {
  position: relative;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}
a.nav-link a:hover:after {
  content: "";
  position: absolute;
  background: #22b0f7;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  right: 0;
}
.navbar-toggler {
  padding: 8px !important;
  font-size: 16px !important;
}
.navbar .container {
  max-width: 1400px;
}
.navbar-brand {
  width: 23%;
}

.btn-register-area .fa.fa-angle-right {
  position: relative;
  right: -3px;
  top: 2px;
}
.navbar-nav > a {
  padding: 0px 25px !important;
}
.nav-item.dropdown {
  padding: 0px 25px;
}
.nav-link {
  padding: 0 0rem !important;
}
.homesec .w-100 {
  object-fit: cover;
  height: 920px;
}
.homesec .carousel-caption {
  top: 44%;
  transform: translatey(-50%);
}

@media (max-width: 1440px) {
  .navbar-brand {
    width: 20%;
  }
  .homesec .w-100 {
    height: 700px;
    object-fit: cover;
  }
  .navbar-nav > a,
  .nav-item.dropdown {
    padding: 0px 12px !important;
  }
  .nav-item.dropdown {
    margin-right: 15px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    flex: 1;
    justify-content: space-between;
  }
}

@media (max-width: 1250px) {
  .navbar-brand {
    width: 14%;
  }
  .navbar-expand-lg .navbar-collapse .btn-register-area {
    margin-left: 0px;
  }
}

@media (max-width: 991px) {
  .navbar-light .navbar-toggler-icon {
    background-image: url(../assets/img/toggle-icon.png) !important;
    width: 24px;
    height: 18px;
  }
  .navbar .navbar-toggler {
    color: #fff !important;
    border-color: #fff !important;
  }
  .navbar-light .navbar-toggler {
    border: 1px solid #fff !important;
  }
  // footer css
  .footer-main {
    justify-content: space-between;
  }
  .footer-main > div {
    padding: 0;
  }
  .navbar-expand-lg .navbar-collapse {
    flex: -moz-available;
  }
  .navbar-expand-lg .navbar-collapse .btn-register-area {
    margin-left: 18px;
    max-width: 100px;
    margin-bottom: 20px;
  }
  .sec1-lt {
    justify-content: flex-end;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .single_member_bg .row {
    height: 100vh;
  }
  .navbar-nav > a,
  .nav-item.dropdown {
    padding: 5px 15px !important;
  }
}

@media (max-width: 767px) {
  .footer-main {
    flex-wrap: wrap;
  }
  .footer-main > div {
    max-width: 45% !important;
    flex: auto;
  }
}

@media (max-width: 480px) {
  .footer-main > div {
    max-width: 100% !important;
    margin: 0 0 15px;
  }
  .ftr-btm p {
    margin-bottom: 0;
  }
  .footer-main > div ul {
    margin: 0 !important;
  }
}
