@import "../node_modules/bootstrap/dist/css/bootstrap.css";

//import utilities
@import "../src/Partials/User-pages/Utilities.scss";

//import header sasss

@import "../src/Partials/Header.scss";

// import ourTeams
@import "../src/Partials/User-pages/ourTeam.scss";

* {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "Teko-SemiBold";
  src: url("./assets/fonts/Teko-SemiBold.ttf");
}

@font-face {
  font-family: "GTWalsheimProRegular";
  src: url("./assets/fonts/GTWalsheimProRegular.ttf");
}

@font-face {
  font-family: "Teko-Regular";
  src: url("./assets/fonts/Teko-Regular.ttf");
}

@font-face {
  font-family: "GTWalsheimPro-Bold";
  src: url("./assets/fonts/GTWalsheimPro-Bold.ttf");
}

@font-face {
  font-family: "Matter-Bold";
  src: url("./assets/fonts/Matter-Bold.ttf");
}

@font-face {
  font-family: "Matter-LightItalic";
  src: url("./assets/fonts/Matter-LightItalic.ttf");
}

@font-face {
  font-family: "GTWalsheimPro-Medium";
  src: url("./assets/fonts/GTWalsheimPro-Medium.ttf");
}

body {
  max-width: 100%;
  margin: 0px auto !important;
  overflow-x: hidden;
  background-color: #000 !important;
}

/* @media (max-width:1920px){
body {
  max-width:100% !important;
}
} */

// coursel setting
.carousel-caption {
  z-index: 1 !important;
}
.carousel-indicators li {
  height: 15px !important;
  width: 15px !important;
  border-radius: 50%;
}
.msn {
  .carousel-indicators {
    li {
      display: none;
    }
  }
}
button:focus {
  outline: none !important;
  border: none !important;
}

h2 {
  font-family: "Teko-SemiBold";
  font-size: 106px;
  color: #fff;
}

h3.sec-hd {
  font-family: "Teko-SemiBold";
  font-size: 82px;
  color: #fff;
  margin: 0 0 40px;
  padding: 0 0 30px;
  position: relative;
}
h3.sec-hd:after {
  content: "";
  position: absolute;
  background: #fff;
  width: 80px;
  height: 4px;
  bottom: 22px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.member-page h3.sec-hd:after,
.trng h3.sec-hd:after,
.trng-smpl h3.sec-hd:after {
  right: auto;
  margin: 0;
}
p.cnt {
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
  color: #fff;
}

p.offer {
  font-family: "Teko-Regular";
  font-size: 30px;
}

.hd-regs {
  max-width: 1605px;
  position: relative;
  width: 100%;
}
button.btn-regs {
  background: #22b0f7;
  color: #fff;
  position: absolute;
  z-index: 999;
  right: 0px !important;
  top: 6rem;
}

/* .hd-regs {
  float: right;
  margin-right: 206px;
  margin: 22px 286px;
} */
/*header*/
.hd-regs {
  // margin-left: 4rem;
  /* margin-top: 22px; */
  margin-top: 1px;
  float: left;
  font-family: "GTWalsheimPro-Bold" !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}

/*section 1*/
.sec-1 {
  /* background-image: url('./assets/img/sec1-bg.png'); */
  //  background-image: url('./assets/img/OurStoryBanner.png');
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.sec1-lt {
  padding: 120px 0px 49px 0px;
}

.single_member_bg {
  .sec1-lt {
    padding: 240px 0px 49px 0px;
  }
}
.sec1-lt h2 {
  font-family: "Teko-SemiBold";
  font-size: 106px;
  color: #fff;
  font-weight: 600;
  text-align: left;
}

button.btn-member {
  background: #22b0f7;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  font-family: "Teko-SemiBold";
  margin-top: 50px;
  border: 0;
  width: 250px;
  height: 50px;
  padding: 2px 0 0 0 !important;
  justify-content: center;
  display: flex;
  align-items: center;
}
.membrshp i.fa.fa-angle-right {
  position: relative;
  top: -1px;
  right: -5px;
}
.membrshp button.btn.btn-member {
  margin: 50px auto 0;
}
button.btn {
  border: 0;
  line-height: normal;
}
.app-dwld-btns {
  display: flex;
  margin-top: 60px;
}

img.img-ply-str {
  margin-left: 15px;
}

.sec1-rt {
  margin-top: 9px;
}

/*Training*/
.trng {
  background: #212023;
}

.trng-lt {
  padding: 187px 259px 213px 0;
}

.trng-ofr {
  background-image: linear-gradient(to right, #2a2a2d, #4e4e511f, #70707200);
  color: #fff;
}

.trng-ofr span {
  font-family: "Teko-SemiBold";
  font-size: 40px;
  color: #22b0f7;
}

.trng-rt {
  margin-top: 100px;
  padding-left: 92px;
}

img.trng-img {
  position: relative;
  z-index: 999;
}

.trng-rt::after {
  content: "";
  background-image: url("./assets/img/layer.png");
  width: 30%;
  height: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 67px;
  left: -120px;
  /* right: 0px; */
}

/* toster css */
.cursor-pointer {
  cursor: pointer;
}

.toast {
  position: fixed;
  z-index: -1;
  top: 50px;
  right: 18px;
  font-size: 16px;
}

.toast-show {
  position: fixed;
  z-index: 999 !important;
  top: 50px;
  right: 18px;
  font-size: 16px;
  opacity: 5;
  display: block;
  background-color: #bfbfbf;
}

/*Mission*/
.msn {
  text-align: center;
  margin-top: 100px;
}

/* .msn::before{
  content: '';
  background-image: url('./assets/img/layer.png');
  width: 30%;
  height: 100%;
  background-repeat: no-repeat;
  position: absolute;
 top: 94%;
 left: 0px;
} */
.msn h2 {
  color: #fff;
}

.msn p {
  color: #fff;
}

img.img-play {
  margin-top: -57%;
}

.msn .carousel-control-prev {
  left: -13%;
}

.msn .carousel-control-next {
  right: -13%;
}

/*Methods*/
.mthds {
  margin-top: 100px;
}

.all-methods {
  margin-top: 0px;
  padding: 0px 460px;
  position: relative;
}

img.img-crcl {
  position: relative;
}

.all-methods h4 {
  font-family: "GTWalsheimPro-Bold";
  font-size: 24px;
  color: #fff;
  margin-top: 27px;
}

.crcl-img {
  text-align: -webkit-center;
}

/*Membership*/
.membrshp {
  background-image: url("./assets/img/member-bg.png");
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  padding: 100px 0px 80px 0px;
}
section.mem-details {
  background: #1c1c1f;
}
.membrshp p {
  margin-top: 43px;
}

.membrshp button {
  margin-top: 50px;
}

/*training sample*/
.trng-smpl {
  background: #161616;
}
.trng-smpl-lt {
  p {
    font-family: "GTWalsheimPro-Bold";
    font-size: 30px;
    letter-spacing: 0.6px;
  }
}
.trng-smpl {
  position: relative;
  padding: 80px 0;
}
.trng-smpl .row {
  align-items: center;
  margin: 0 auto;
}
.trng-smpl::after {
  content: "";
  background-image: url(./assets/img/layer.png);
  width: 290px;
  height: 400px;
  background-repeat: no-repeat;
  position: absolute;
  left: -145px;
  background-size: cover;
  top: 16%;
  opacity: 0.5;
  bottom: -70px;
}
.container {
  max-width: 1400px !important;
}

/*testimonial*/
.testimony {
  background-image: url("./assets/img/testi-bg.png");
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.testimony h3 {
  padding-top: 50px;
}
section.testimony {
  padding: 40px 0 100px;
  background-size: cover;
}

.testimonials-list h3 {
  padding-top: 20px;
  font-family: "Matter-Bold";
  color: #161616;
  font-size: 24px;
}

.testi-box {
  max-width: 100%;
  /* width: 945px; */
  border-radius: 10px;
}
.testimony .item {
  min-height: 528px;
  border-radius: 20px !important;
  background: #ffffff5c;
}

.testi-cnt {
  padding: 138px 40px 30px 130px;
}

.testi-cnt p {
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
  padding-right: 40px;
}

.testi-cnt span {
  font-family: "Matter-LightItalic";
  font-size: 18px;
  color: #666666;
}

.testi-box .imgpic img {
  max-width: 100%;
  width: 122px !important;
  height: 122px !important;
  object-fit: cover;
  border-radius: 10px;
}

.imgpic::after {
  content: "";
  position: absolute;
  border: 1px solid #000;
  width: 12rem;
  margin-left: 13%;
  margin-top: -5%;
}

img.img-testi {
  width: 64px !important;
  margin-top: 60px;
  margin-right: 40px;
}

.img-cmnt {
  float: right;
}

.imgpic {
  padding-top: 30px;
  margin-left: -30px;
  float: left;
  opacity: 0.5;
}

.testimony .owl-item.active > .item {
  background: #22b0f7;
  color: #fff;
  width: 100%;
}
.testimony .owl-item.active .imgpic {
  opacity: 1;
}

.owl-item.active h3 {
  padding-top: 20px;
  font-family: "Matter-Bold";
  color: #fff;
  font-size: 24px;
}

.owl-item.active span {
  font-family: "Matter-LightItalic";
  font-size: 18px;
  color: #fff;
}

.owl-item.active .imgpic::after {
  content: "";
  position: absolute;
  border: 1px solid #fff;
  width: 12rem;
  margin-left: 14%;
  margin-top: -6%;
}

/*register form*/
.register-form {
  background: #ffffff 0% 0% no-repeat padding-box;

  border-radius: 10px;
  padding: 30px 40px;
  margin: auto;
  max-width: 580px;
  width: 100%;
}

.register-form label {
  margin-top: 15px;
  font-family: "GTWalsheimPro-Medium";
  font-size: 16px;
  color: #333;
}

input.reg-frm {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 100%;
  padding: 11px;
}

.register-form h3 {
  font-family: "Teko-SemiBold";
  font-size: 30px;
  color: #333333;
  margin-top: 20px;
}

button.btn-register {
  background: #22b0f7;
  color: #fff;
  width: 100%;
  height: auto;
  font-family: "Teko-SemiBold";
  font-size: 20px;
}

span.acpt {
  color: #aeaeae !important;
  font-size: 16px !important;
  font-family: "GTWalsheimProRegular !important";
}

.register-form span {
  color: #22b0f7;
  font-family: "GTWalsheimPro-Bold";
  font-size: 16px;
}

::placeholder {
  color: #aeaeae;
}

.register-form p {
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
  text-align: center;
  color: #aeaeae;
}

p.text-danger {
  text-align: left !important;
}

/*Login*/

.viewIcon {
  position: absolute;
  top: 59px;
  right: 12px;
}

.registerBtn {
  position: absolute;
  top: 0;
  right: 19rem;
  z-index: 999;
}

.padRight {
  padding-right: 2rem !important;
}

.login-form {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* border: 1px solid #707070; */
  border-radius: 10px;
  padding: 30px 40px;
  margin: auto;
  max-width: 580px;
  width: 100%;
}

.login-form label {
  margin-top: 15px;
  font-family: "GTWalsheimPro-Medium";
  font-size: 16px;
  color: #333;
}

.login-form p {
  color: #aeaeae;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  text-align: center;
}

.login-form span {
  color: #22b0f7;
  font-family: "GTWalsheimPro-Bold";
  font-size: 16px;
}

.login-form h3 {
  font-family: "Teko-SemiBold";
  font-size: 30px;
  color: #333;
  margin-top: 20px;
}

input.reg-frm {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 100%;
  padding: 11px;
  border: none !important;
  outline: none !important;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  color: #b1b1b1;
}

button.btn-login {
  background: #22b0f7;
  color: #fff;
  width: 100%;
  height: auto;
  font-family: "Teko-SemiBold";
  font-size: 20px;
  margin-top: 10px;
}

/*Purchase subscribe*/
.purchs-subs {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 30px 40px;
  margin: auto;
  max-width: 580px;
  width: 100%;
}

button.btn-purchase {
  background: #22b0f7;
  color: #fff;
  width: 100%;
  height: auto;
  margin-top: 40px;
  font-family: "Teko-SemiBold";
  font-size: 20px;
}

h3.prchs-subs {
  margin-top: 20px;
  font-family: "Teko-SemiBold";
  font-size: 30px;
  color: #333333;
}

h3.smr-trng {
  margin-top: 40px;
  font-family: "Teko-SemiBold";
  font-size: 30px;
  color: #333333;
}

.purchs-subs p {
  font-size: 30px;
  font-family: "Teko-Regular";
  color: #666666;
  margin-top: 10px;
}

span.new-prc {
  font-size: 40px;
  color: #22b0f7;
  font-family: "Teko-SemiBold";
}

button.close {
  color: #22b0f7;
}

.closeaBtnStripe {
  color: #22b0f7;
  position: relative;
  right: 25px;
  top: 5px;
}

/*Thanks purchase*/
.thnks-prchs {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 30px 40px;
  margin: auto;
  max-width: 580px;
  width: 100%;
}

.thnks-prchs h3 {
  margin-top: 40px;
  font-family: "Teko-SemiBold";
  font-size: 30px;
  color: #333333;
}

.thnks-prchs p {
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
  color: #666666;
  margin-top: 30px;
}

.thnk-dwld-btns {
  margin-top: 5px;
}

.aps {
  justify-content: center;
}

.aps img {
  margin-top: 43px;
}

/*Footer*/
.ftr-navbar {
  display: flex;
  padding: 0px 530px;
  margin-top: 50px;
}

li.ftr-nav-item {
  display: inline-flex;
}

a.ftr-nav-link {
  color: #fff;
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
  padding: 34px 18px;
}
.ftr-btm {
  margin-top: 80px;
}
.ftr-btm p {
  color: #fff;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
}
.footer-main .col-4 p {
  font-size: 16px;
  margin-top: 15px;
}

.ftr-btm span {
  color: #fff;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
}

.sm-ico {
  display: inline-block;
  position: relative;
}

.sm-ico span i.fa {
  color: #000;
  margin: 0px 10px;
  border: 1px solid #fff;
  width: 32px;
  border-radius: 20px;
  height: 32px;
  font-size: 16px;
  padding: 8px;
  background: #666666;
}

.sm-ico span i.fa:hover {
  color: #000;
  margin: 0px 10px;
  border: 1px solid #fff;
  width: 32px;
  border-radius: 20px;
  height: 32px;
  font-size: 16px;
  padding: 8px;
  background: #22b0f7;
}

.sm-ico span {
  color: #fff;
}

/*Media Query*/
@media (max-width: 360px) {
  .registerBtn {
    top: 0;
    right: 1rem;
  }

  button.btn-member {
    font-size: 15px !important;
  }

  h3.sec-hd {
    font-size: 30px !important;
  }

  p.cnt {
    font-size: 12px !important;
  }
  .sec1-lt h2 {
    font-size: 30px !important;
  }

  .trng-lt {
    padding: 50px 30px 30px 30px !important;
  }

  p.offer {
    font-size: 12px !important;
  }

  .trng-ofr span {
    font-size: 12px !important;
  }

  .all-methods {
    padding: 0px 30px !important;
  }

  .membrshp {
    padding: 50px 30px 30px 30px !important;
  }

  .testi-cnt {
    padding: 60px 40px 30px 30px !important;
  }

  .testi-cnt p {
    padding-right: 0px !important;
  }

  button.owl-prev {
    left: 19% !important;
  }

  button.owl-next {
    right: 19% !important;
  }

  .imgpic {
    display: none !important;
  }

  // img.img-testi {
  //   width: 40px !important;
  //   margin-top: 30px !important;
  //   margin-right: 10px !important;
  // }

  .ftr-navbar {
    padding: 0px 30px !important;
  }

  .sm-ico span i.fa {
    margin: 0px 7px !important;
  }
}

@media (min-width: 360px) and (max-width: 576px) {
  .registerBtn {
    top: 0 !important;
    right: 1rem !important;
    z-index: 999 !important;
  }

  h3.sec-hd {
    font-size: 40px !important;
  }

  p.cnt {
    font-size: 15px !important;
  }

  .sec1-lt h2 {
    font-size: 45px !important;
  }

  .trng-lt {
    padding: 50px 35px 30px 35px !important;
  }

  p.offer {
    font-size: 12px !important;
  }

  .trng-ofr span {
    font-size: 12px !important;
  }

  .all-methods {
    padding: 0px 30px !important;
  }

  .membrshp {
    padding: 50px 35px 30px 35px !important;
  }
  .testi-cnt {
    padding: 138px 0px 0px 39px !important;
  }

  button.owl-prev {
    left: 14% !important;
  }

  button.owl-next {
    right: 14% !important;
  }

  .imgpic {
    padding-top: 30px !important;
    margin-left: -5% !important;
  }

  img.img-testi {
    width: 40px !important;
    margin-top: 40px !important;
    margin-right: 20px !important;
  }

  .owl-item.active .imgpic::after {
    /* width: 5rem !important;
      margin-left: 41% !important; */
    display: none;
  }

  .ftr-navbar {
    padding: 0px 35px !important;
  }
  .msn {
    margin-top: 40px !important;
  }

  .mthds {
    margin-top: 20px !important;
  }

  .sm-ico span i.fa {
    margin: 0px 7px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .registerBtn {
    top: 0;
    right: 2rem;
    z-index: 999;
  }

  h3.sec-hd {
    font-size: 45px !important;
  }

  p.cnt {
    font-size: 18px !important;
  }

  .sec1-lt h2 {
    font-size: 45px !important;
  }

  .trng-lt {
    padding: 50px 40px 30px 40px !important;
  }

  p.offer {
    font-size: 15px !important;
  }

  .trng-ofr span {
    font-size: 15px !important;
  }

  .all-methods {
    padding: 0px 40px !important;
  }

  .membrshp {
    padding: 50px 40px 30px 40px !important;
  }

  .ftr-navbar {
    padding: 0px 40px !important;
  }
  .msn {
    margin-top: 70px !important;
  }

  .mthds {
    margin-top: 70px !important;
  }

  button.owl-prev {
    left: 19% !important;
  }

  button.owl-next {
    right: 19% !important;
  }

  .testi-cnt {
    padding: 138px 10px 30px 50px !important;
  }

  .imgpic {
    padding-top: 40px !important;
    margin-left: -39px !important;
  }

  .owl-item.active .imgpic::after {
    width: 12rem !important;
    margin-left: 28% !important;
    margin-top: -8% !important;
  }

  img.img-testi {
    width: 50px !important;
    margin-top: 60px !important;
    margin-right: 20px !important;
  }
}

@media (max-width: 767px) {
  .owl-item.active .imgpic::after {
    display: none;
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .registerBtn {
    right: 3rem !important;
  }

  h3.sec-hd {
    font-size: 45px !important;
  }

  p.cnt {
    font-size: 18px !important;
  }

  .sec1-lt h2 {
    font-size: 45px !important;
  }

  .trng-lt {
    padding: 50px 40px 30px 40px !important;
  }

  p.offer {
    font-size: 15px !important;
  }

  .trng-ofr span {
    font-size: 15px !important;
  }

  .all-methods {
    padding: 0px 40px !important;
  }

  .membrshp {
    padding: 50px 40px 30px 40px !important;
  }
  .ftr-navbar {
    padding: 0px 40px !important;
  }
  button.owl-prev {
    left: 18% !important;
  }

  button.owl-next {
    right: 18% !important;
  }

  .testi-cnt {
    padding: 133px 40px 30px 79px !important;
  }

  .imgpic {
    padding-top: 25px !important;
    margin-left: -35px !important;
  }

  .owl-item.active .imgpic::after {
    margin-left: 28% !important;
    margin-top: -9% !important;
  }

  img.img-testi {
    width: 50px !important;
    margin-top: 50px !important;
    margin-right: 35px !important;
  }

  .msn {
    margin-top: 70px !important;
  }

  .mthds {
    margin-top: 70px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .registerBtn {
    right: 5rem !important;
  }

  h3.sec-hd {
    font-size: 50px !important;
  }

  p.cnt {
    font-size: 20px !important;
  }

  .sec1-lt h2 {
    font-size: 40px !important;
  }

  .trng-lt {
    padding: 150px 150px 130px 30px !important;
  }

  p.offer {
    font-size: 25px !important;
  }

  .trng-ofr span {
    font-size: 25px !important;
  }

  .all-methods {
    padding: 0px 45px !important;
  }

  .membrshp {
    padding: 50px 45px 50px 45px !important;
  }

  .ftr-navbar {
    padding: 0px 150px !important;
  }
  .msn {
    margin-top: 80px !important;
  }

  .mthds {
    margin-top: 80px !important;
  }

  button.owl-prev {
    left: 18% !important;
  }

  button.owl-next {
    right: 18% !important;
  }

  .imgpic {
    padding-top: 35px !important;
  }

  .owl-item.active .imgpic::after {
    width: 12rem !important;
    margin-left: 17% !important;
    margin-top: -5% !important;
  }

  .testi-cnt {
    padding: 122px 40px 30px 81px !important;
  }

  img.img-testi {
    width: 54px !important;
    margin-top: 59px !important;
    margin-right: 32px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1367px) {
  .single_member_bg .row.container > div {
    padding: 0;
  }
  h3.sec-hd {
    font-size: 60px !important;
  }

  .registerBtn {
    right: 10rem !important;
  }

  p.cnt {
    font-size: 18px !important;
  }
  .sec1-lt h2 {
    font-size: 60px !important;
  }

  .trng-lt {
    padding: 170px 180px 30px 50px !important;
  }

  p.offer {
    font-size: 25px !important;
  }

  .trng-ofr span {
    font-size: 25px !important;
  }

  .all-methods {
    padding: 0px 250px !important;
  }

  .membrshp {
    padding: 50px 50px 60px 50px !important;
  }

  .ftr-navbar {
    padding: 0px 200px !important;
  }

  .msn {
    margin-top: 80px !important;
  }

  .mthds {
    margin-top: 80px !important;
  }

  button.owl-prev {
    left: 16% !important;
  }

  button.owl-next {
    right: 16% !important;
  }

  .imgpic {
    padding-top: 40px !important;
  }

  .owl-item.active .imgpic::after {
    margin-top: -4% !important;
  }

  img.img-testi {
    width: 58px !important;
    margin-right: 40px !important;
  }

  .testi-cnt {
    padding: 131px 40px 30px 71px !important;
  }
}

@media (min-width: 1367px) and (max-width: 1600px) {
  .registerBtn {
    right: 12rem !important;
  }

  h3.sec-hd {
    font-size: 70px !important;
  }

  p.cnt {
    font-size: 18px !important;
  }
  .sec1-lt h2 {
    font-size: 70px !important;
  }

  .trng-lt {
    padding: 180px 230px 200px 0 !important;
  }

  p.offer {
    font-size: 18px !important;
  }

  .trng-ofr span {
    font-size: 18px !important;
  }

  .all-methods {
    padding: 0px 350px !important;
  }

  .membrshp {
    padding: 50px 50px 50px 50px !important;
  }

  .ftr-navbar {
    padding: 0px 50px !important;
  }
  .msn {
    margin-top: 80px !important;
  }

  .mthds {
    margin-top: 80px !important;
  }

  button.owl-prev {
    left: 23% !important;
  }

  button.owl-prev {
    right: 23% !important;
  }

  .testi-cnt {
    padding: 138px 40px 30px 70px !important;
  }

  .owl-item.active .imgpic::after {
    margin-left: 15% !important;
    margin-top: -5% !important;
  }
}

@media (min-width: 1600px) and (max-width: 1920px) {
  h3.sec-hd {
    font-size: 80px !important;
  }
  .sec1-lt h2 {
    font-size: 88px !important;
  }

  .trng-lt {
    padding: 187px 180px 213px 0px !important;
  }

  p.offer {
    font-size: 18px !important;
  }

  .trng-ofr span {
    font-size: 18px !important;
  }

  .all-methods {
    padding: 0px 200px !important;
  }

  .ftr-navbar {
    padding: 0px 180px !important;
  }
  .msn {
    margin-top: 80px !important;
  }

  .mthds {
    margin-top: 80px !important;
  }

  .owl-item.active .imgpic::after {
    margin-left: 13% !important;
    margin-top: -5% !important;
  }
}

.circle-img {
  height: 193px;
  background-image: url(./assets/img/blue-crcl1.svg);
  width: 193px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.owl-nav {
  text-align: center;
}

button.owl-prev {
  position: absolute;
  top: 50%;
  left: 24%;
  transform: translatey(-50%);
}

button.owl-next {
  position: absolute;
  top: 50%;
  right: 24%;
  transform: translatey(-50%);
}

.hd-hr {
  background-color: #fff;
  height: 2px;
  width: 100px;
  margin: 1rem 0;
  text-align: -webkit-center;
  margin: 0 auto;
}

.hd {
  text-align: -webkit-center;
  margin: 0 0 20px;
}

.ftr-btm-lt {
  text-align: center;
}

/* .img-cmnt{
  display: none;
}
.imgpic::after{
  content: '';
  background-image: url('../img/blk-cmnt.svg');
  position: absolute;
  width: 100%;
} */

.footer-main {
  display: flex;
  color: #adadad;
  margin-bottom: 3rem;
  width: 100%;
  font-size: 16px;
  line-height: 2;
  font-family: "GTWalsheimProRegular";
  justify-content: space-between;
}

.footer a {
  text-decoration: none;
  color: #adadad;
  display: flex;
  align-items: center;
}

.footer-main li {
  list-style: none;
}

.footer-main a :active {
  color: #22b0f7;
}

.footer-main svg {
  margin-right: 10px;
}

.footer {
  padding: 40px 0;
}
.our-team-main .sec1-lt {
  padding: 240px 0px 150px 0px !important;
}
.our-team-main .row.container {
  margin: 0 auto !important;
  max-width: 1400px;
}
.ourPhilosophy .col-6 img {
  width: 100%;
}
.single_member_bg .row.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
}
button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.homesec .sec1-lt {
  position: relative;
  left: -18px;
}
.homesec .sec1-lt .fa.fa-angle-right {
  position: relative;
  top: -1px;
  right: -5px;
}
.homesec .sec1-lt .btn-member {
  height: 50px;
  letter-spacing: 1px;
}
.trng .btn-member {
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trng .fa.fa-angle-right {
  position: relative;
  top: -2px;
  right: -4px;
}
.footer .row {
  padding: 0 15px;
}
.our-team-main .link_nav_con li {
  position: relative;
}
.our-team-main .link_nav_con li:after {
  content: "";
  position: absolute;
  background: #fff;
  width: 2px;
  height: 16px;
  top: 50%;
  transform: translatey(-50%) rotate(25deg);
  font-style: initial;
  right: 0px;
}
.our-team-main .link_nav_con li:last-child:after {
  background: none;
}
.team-items .card-body {
  padding: 1.25rem 0 50px;
}
.single_member_bg .link_nav_con li {
  position: relative;
}
.single_member_bg .link_nav_con li:after {
  content: "";
  position: absolute;
  background: #fff;
  width: 2px;
  height: 16px;
  top: 50%;
  transform: translatey(-50%) rotate(25deg);
  font-style: initial;
  right: 0px;
}
.single_member_bg .link_nav_con li:last-child:after {
  background: none;
}
.single_member_bg .link_nav_con li {
  padding-right: 0.7rem;
}
.member-page button.btn-member {
  width: 150px;
}
.member-page button.btn-member i.fa.fa-angle-right {
  position: relative;
  right: -4px;
}

.homesec .carousel-control-next-icon {
  background-image: url(./assets/img/arrow-right.png) !important;
  width: 50px !important;
  height: 50px !important;
}
.homesec .carousel-control-prev-icon {
  background-image: url(./assets/img/arrow-right.png) !important;
  width: 50px !important;
  height: 50px !important;
  transform: rotate(180deg);
}
.dropdown-toggle::after {
  background-image: url(./assets/img/arrow-down.png) !important;
  content: "" !important;
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-position: center;
  border: 0 !important;
  bottom: 6px;
  position: absolute;
}
.detail-img img {
  width: 100%;
}
.homesec:before {
  content: "";
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
}
.homesec .btn {
  padding: 0.375rem 0.75rem 3px;
}
.dropdown > a {
  position: relative;
}
.dropdown > a:hover:before {
  content: "";
  position: absolute;
  background: #22b0f7;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  right: 0;
}
.trng-rt img {
  width: 100%;
  padding-right: 70px;
}

@media screen and (min-device-width: 950px) and (max-device-width: 1100px) {
  .trng-rt {
    padding: 10px;
    img {
      padding: 10px;
    }
  }
}

// media query statrt

@media (max-width: 1440px) {
  .hd-regs {
    padding: 20px 40px !important;
  }
  button.btn-member {
    margin-top: 30px;
  }
  .app-dwld-btns {
    margin-top: 40px;
  }
  .homesec .w-100 {
    min-height: 700px;
  }
  .container {
    padding: 0 40px !important;
  }
  .navbar .container {
    padding: 20px 40px !important;
  }
  .ourPhilosophy .container {
    margin: 40px 0;
  }
  .membership-body {
    padding: 50px 0;
  }
}

@media (max-width: 991px) {
  .hd-regs {
    padding: 20px 20px !important;
  }
  .trng-rt {
    padding-left: 20px;
  }
  .trng-lt {
    padding: 50px 0px 30px 0px !important;
  }
  .membrshp {
    margin-top: 40px;
  }
  .msn {
    margin-top: 40px;
  }
  .mthds {
    margin-top: 20px;
  }
  .testimony h3 {
    padding-top: 40px;
  }
  .footer {
    padding: 20px 0;
  }
  .msn .carousel-control-next {
    right: 0;
  }
  .homesec .sec1-lt {
    left: 0;
  }
  .testi-box .imgpic img {
    width: 80px !important;
    height: 80px !important;
  }
  .ftr-btm p br {
    display: none;
  }
  .ftr-btm p {
    font-size: 14px;
    line-height: normal;
    margin: 10px 0 0;
  }
  .container {
    padding: 0 20px !important;
  }
  .price-slip {
    max-width: 500px;
    min-height: 400px;
  }
  .navbar .container {
    padding: 20px 20px !important;
  }
  .navbar {
    padding: 0.5rem 0rem !important;
  }
  .our-team-main .navbar .container {
    padding: 20px 0px !important;
  }
  .dropdown-toggle::after {
    bottom: 10px;
  }
  .mem-details {
    padding: 50px 0 0;
  }
  .nav-item.dropdown > a {
    display: inline-block;
  }
  .dropdown-menu.show {
    max-width: 100px;
    margin-top: 7px !important;
  }
  .our-team-main .sec1-lt {
    padding: 190px 0px 80px 0px !important;
  }
  .our-team-main {
    min-height: 350px;
  }
  .custm-pad {
    padding: 0 !important;
  }
  .our-team-main .row.container {
    padding: 0 !important;
  }
  .our-team-main .row.container > div,
  .single_member_bg .row.container > div {
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .footer-main {
    margin-bottom: 1rem;
  }
  .trng-rt {
    margin-top: 30px;
  }
  .circle-img {
    margin: 0 auto;
  }
  .all-methods h4 {
    margin-top: 10px;
  }
  .circle-img {
    height: 150px;
    width: 150px;
  }
  p.cnt {
    margin: 0 0 25px;
  }
  .membrshp button {
    margin-top: 10px;
  }
  .membrshp {
    margin-top: 20px;
    padding: 30px 30px 30px 30px !important;
  }
  button.btn-member {
    margin-top: 10px;
  }
  .our-team-main .row.container {
    padding: 0 !important;
  }
  .our-team-main .row.container > div {
    padding: 0 !important;
  }
  .our-team-main {
    min-height: 400px;
  }
  .ftr-btm {
    padding: 0px 15px !important;
    margin-top: 30px;
  }
  .footer .row {
    padding: 0 15px;
  }
  .trng-smpl .row > div:last-child {
    margin: 0 0 30px;
  }
  .trng-smpl .row {
    flex-direction: column-reverse;
  }
  .container {
    padding: 0 15px !important;
  }
  .trng-smpl .row,
  .trng-smpl .row > div {
    padding: 0 !important;
  }
  .trng-smpl {
    padding: 40px 0;
  }
  .purchs-subs {
    padding: 10px 0;
  }
  .promo-code input {
    font-size: 14px;
    padding: 0 0 0 40px;
  }
  .single_member_bg .row.container {
    padding: 0 !important;
  }
  .single_member_bg .navbar .container {
    padding: 20px 0px !important;
  }
}

.mthds {
  padding-bottom: 80px;
}
.trng-smpl i.fa.fa-angle-right {
  position: relative;
  right: -5px;
}
