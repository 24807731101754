.team-items {
  display: block;
  @media screen and (max-width: 767px) {
    .card {
      width: 60%;
      margin: auto;
      .card-img-top {
        height: 400px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .card {
      width: 80%;
      .card-img-top {
        height: 350px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .card {
      width: 90%;
    }
  }
}
.detail-view {
  color: #fff;
}

.membership-area {
  color: #fff;
  flex-direction: column;
  padding-top: 115px;
  padding-bottom: 100px;
  p {
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    font-family: "GTWalsheimProRegular";
  }
  .italic-text-area {
    .colored-text {
      font-size: 30px;
      font-family: "Matter-LightItalic";
    }
  }
}

.con-container {
  display: flex;
}
.cont-head {
  padding: 1rem;
  font-size: 78px;
  color: #fff;
}
.social-icons-contact {
  ul {
    display: flex;

    li {
      padding: 5px;
      list-style: none;
      img {
        width: 90%;
      }
    }
  }
}
.team-members {
  padding-top: 100px;
  background: #1c1c1f;
  .team-items {
    margin-top: 74px;
  }
  .card-title {
    font-size: 45px;
    font-family: "Teko-SemiBold";
  }
  .card-text {
    font-family: "GTWalsheimProRegular";
    color: #adadad;
    font-size: 22px;
    min-height: 66px !important;
  }

  .card-body {
    a {
      &.btn {
        color: #22b0f7;
        font-family: "GTWalsheimPro-Bold";
        padding-left: 0;
      }
    }
  }
  .card-img-top {
    height: 540px;
    object-fit: cover;
    object-position: 100% 0%;
    border-radius: 20px;
  }
}
.team-items {
  .card-body {
    .fa-angle-right {
      color: #22b0f7;
    }
  }
}
.mem-details {
  .detail-view {
    padding-top: 115px;
    display: flex;
    @media screen and (min-width: 992px) {
      padding-left: 0;
    }
    h5 {
      font-size: 30px;
      color: #22b0f7;
      font-family: "Matter-LightItalic";
      margin-bottom: 40px;
    }
    p {
      line-height: 32px;
      font-size: 18px;
      font-family: "GTWalsheimProRegular";
      padding-right: 60px;
    }
  }
}
.single_member_bg {
  background-image: url("../../assets/img/OurTeamBG.png");
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 550px;
  .link_nav_con {
    li {
      list-style-type: none;
      margin-right: 0.7rem;
      &:last-child {
        margin-right: 0;
      }
      a {
        text-decoration: none;
        color: #fff;
        font-family: "GTWalsheimPro-Medium";
        &.active_hero {
          color: #22b0f7;
        }
      }
    }
  }
}
.our-team-main {
  background-image: url("../../assets/img/OurTeamBG.png");
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 550px;
  .link_nav_con {
    li {
      list-style-type: none;
      margin-right: 0.7rem;
      padding-right: 0.8rem;
      &:last-child {
        margin-right: 0;
      }
      a {
        text-decoration: none;
        color: #fff;
        font-family: "GTWalsheimPro-Medium";
        &.active_hero {
          color: #22b0f7;
        }
      }
    }
  }
}
.contact-heading {
  font-size: 62px;
  color: #fff;
}
.conct-text {
  font-size: 18px;
  line-height: 2;
  color: #fff;
}

.cntform {
  padding: 1rem;
  background-color: #000000;
  form {
    .name-area {
      display: flex;
      justify-content: space-between;
      .first-name,
      .last-name {
        width: 47%;
      }
    }
    label {
      color: #fff;
      line-height: 2;
      font-family: "GTWalsheimProRegular";
    }
    input,
    textarea {
      background-color: #363636;
      padding: 0.8rem;
      border-radius: 6px;
      border: none;
      font-size: 18px;
      color: #ffffff;
      font-family: "GTWalsheimProRegular";
      font-style: italic;
      width: 100%;
    }
    .form-button {
      font-size: 24px;
      padding: 0.5rem 1.5rem;
      background-color: #23b0f7;
      border-radius: 4px;
      border: none;
      color: #fff;
      width: 170px;
      font-family: "Teko-SemiBold";
    }
  }
}
//section ourPhilosophy
.ourPhilosophy {
  padding-top: 115px;
  padding-bottom: 20px;
  .text-philoso {
    p {
      font-size: 18px;
      line-height: 32px;
      font-family: "GTWalsheimProRegular";
      color: #fff;
      margin-bottom: 30px;
      padding-right: 60px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .container {
    &:first-child {
      padding-bottom: 100px;
    }
  }
  .container {
    &:last-child {
      p {
        padding-right: 0;
      }
    }
  }
}
// our story section
.ourPhilosophy.ourstory {
  .container {
    &:last-child {
      padding-top: 100px;
    }
  }
}
//contact  body
.contact-body {
  padding-top: 115px;
  padding-bottom: 100px;
  background-color: #1c1c1f;
  .cont.social {
    .cont-head {
      margin-top: 80px;
      margin-bottom: 60px;
      font-family: "Teko-SemiBold";
    }
  }
  .contact-heading {
    font-family: "Teko-Regular";
    font-size: 80px;
    margin-bottom: 60px;
  }
  p {
    font-family: "GTWalsheimProRegular";
    line-height: 32px;
  }
  .cntform {
    margin-top: 50px;
    background-color: #000000;
    padding: 28px 23px;
    .name-area {
      margin-bottom: 50px;
    }
    .email {
      margin-bottom: 50px;
    }
    .message-area {
      margin-bottom: 50px;
      textarea {
        min-height: 158px;
      }
    }
  }
  .cont-head {
    padding-left: 0;
  }
  .pr_form {
    padding-left: 65px;
  }
  .pl_form {
    padding-right: 65px;
  }
}
// after
.cont-head {
  position: relative;
  &::after {
    content: "";
    background-color: #ffffff;
    height: 3px;
    width: 68px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.contact-heading {
  position: relative;
  &::after {
    content: "";
    background-color: #ffffff;
    height: 3px;
    width: 68px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.mar_top_contact {
  margin-top: 40px;
}
label {
  sup {
    color: #ff0b0b;
  }
}

.card {
  background-color: transparent !important;
}

.price-slip {
  width: 100%;
  background: #000000;
  border-radius: 7px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 1rem 4rem 1rem;
  margin: auto;
  max-width: 607px;
  min-height: 499px;
  .mem-price {
    font-size: 82px;
  }
  .mem-time {
    font-size: 48px;
  }
}

.membership-body {
  background: #1c1c1f;
}
.membership-body .btn.btn-member {
  width: 309px;
  margin: 30px auto 0;
  height: 61px;
}

.ourPhilosophy .container {
  display: flex;
}
.ourPhilosophy .container:first-child .col-6 img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: 100% 100%;
}

.ourPhilosophy .text-philoso p span {
  color: #22b0f7;
}
.membership-body .colored-text {
  margin: 0 0 50px;
}
.cntform form input::placeholder,
.cntform form textarea::placeholder {
  color: #797979;
}

@media (max-width: 1440px) {
  .cont-img img {
    width: 100%;
  }
  .contact-body .contact-heading {
    font-size: 60px;
  }
  .cont-head {
    font-size: 40px;
  }
  .mem-details {
    padding: 50px 0;
  }
  .detail-img img {
    width: 100%;
  }
  .mem-details .detail-view p {
    font-size: 16px;
    padding-right: 0;
    line-height: 28px;
  }
  .price-slip .mem-price {
    font-size: 60px;
  }
  .price-slip .mem-time {
    font-size: 35px;
  }
}

@media (max-width: 1280px) {
  .team-members .card-img-top {
    height: 350px;
  }
}

@media (max-width: 991px) {
  div#basic-navbar-nav {
    background: #000;
  }
  .navbar-light .navbar-nav .nav-link {
    padding-left: 10px;
  }
  .homesec .carousel-item > img {
    min-height: 500px;
    object-fit: cover;
    margin-bottom: -1px;
  }
  .homesec .sec1-lt {
    text-align-last: center !important;
  }
  .team-members {
    padding-top: 40px;
    padding-bottom: 0;
  }
  .team-members .card-title {
    font-size: 25px;
    margin-bottom: 0;
  }
  .team-members .card-text {
    font-size: 15px;
    margin-bottom: 0;
  }
  .team-members .card-img-top {
    height: 300px;
  }
  .team-members .team-items {
    margin-top: 30px;
  }
  .ourPhilosophy .text-philoso p {
    padding-right: 0px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .ourPhilosophy .container:first-child {
    flex-direction: column-reverse;
    padding-bottom: 50px;
  }

  .ourPhilosophy .container {
    flex-wrap: wrap;
  }
  .ourPhilosophy .container > .col-6 {
    flex: auto;
    max-width: 100%;
    padding: 0;
  }
  .ourPhilosophy .col-6 img {
    margin: 0 0 20px;
  }
  .ourPhilosophy {
    padding-top: 40px;
  }
  .ourPhilosophy.ourstory .container:last-child {
    padding-top: 30px;
  }
  .ourPhilosophy .container .col-12 {
    padding: 0;
  }
  .price-slip .mem-price {
    font-size: 60px;
  }
  .price-slip .mem-time {
    font-size: 40px;
  }
  .membership-area {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .membership-area p {
    font-size: 16px;
  }
  .mar_top_contact {
    margin-top: 20px;
  }
  .contact-body .pl_form {
    padding-right: 0;
    padding-left: 0;
  }
  .cntform form input,
  .cntform form textarea {
    padding: 0.4rem;
    font-size: 14px;
  }
  .contact-body {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .detail-img img {
    width: 100%;
    margin: 0 0 20px;
  }
  // .detail-view {
  //   padding-left: 0 !important;
  // }
  .mem-details {
    .detail-view {
      flex-direction: column-reverse;
      @media screen and (min-width: 992px) {
        .detail-view.container {
          padding-left: 0;
        }
      }
    }
  }
  .detail-view > .col-6 {
    flex: auto;
    max-width: 100%;
    padding: 0;
  }
  .mem-details .detail-view p {
    padding-right: 0;
  }
  .mem-details .detail-view {
    padding-top: 50px;
  }
  .team-members .team-items .row > .col-4 {
    padding: 0 5px;
  }
  .membership-body .btn.btn-member {
    width: 100%;
    height: 50px;
  }
  .sec1-lt {
    justify-content: center;
    align-items: flex-start;
  }
  button.btn-member {
    width: 100%;
    max-width: 200px;
  }
}

@media (max-width: 767px) {
  .homesec .carousel-caption {
    right: 0;
    left: 0;
  }
  a.navbar-brand img {
    width: 50px;
  }
  .team-items .row > div {
    width: 100%;
    max-width: 100%;
    flex: auto;
  }
  .contact-body .contact-heading {
    font-size: 40px;
  }
  .cont-head {
    font-size: 30px;
  }
  .container.con-container {
    flex-wrap: wrap;
  }
  .contact-body .col-6 {
    flex: auto;
    max-width: 100%;
  }
  .contact-body .pr_form {
    padding-left: 0;
    margin-top: 25px;
  }
  .contact-body .cont.social .cont-head {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 1rem 0;
  }
  .single_member_bg .d-flex {
    flex-wrap: wrap;
  }
  .app-dwld-btns .img-fluid {
    max-width: 120px;
  }
}

@media (max-width: 480px) {
  .team-members .card-img-top {
    height: 250px;
    object-position: 100% 0%;
  }
  .team-members .card-body {
    padding-left: 0;
  }
  .team-members .card-body a {
    padding-left: 0;
  }
  .our-team-main .link_nav_con li a {
    font-size: 16px;
  }
  .price-slip {
    max-width: 100%;
  }
  .price-slip .mem-price {
    font-size: 40px;
  }
  .price-slip .mem-time {
    font-size: 30px;
  }
  .cntform form .name-area {
    flex-wrap: wrap;
  }
  .cntform form .name-area .first-name,
  .cntform form .name-area .last-name {
    width: 100%;
    margin: 0 0 20px;
  }
  .contact-body .cntform .name-area {
    margin-bottom: 10px;
  }
  .contact-body .cntform .email {
    margin-bottom: 20px;
  }
  .contact-body .cntform .message-area textarea {
    min-height: 110px;
  }
  .single_member_bg .sec1-lt {
    padding: 30px 3px 40px 0px !important;
  }
  .mem-details .detail-view h5 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  span.navbar-brand img {
    width: 55px;
  }
  .team-members .team-items .row > .col-4 {
    padding: 0 15px;
  }
  .cntform form .form-button {
    padding: 0rem 1rem;
  }
  .our-team-main .d-flex {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.footer a:hover {
  color: #22b0f7;
  text-decoration: none;
}
.ftr-btm .col-2 p {
  font-family: "GTWalsheimPro-Bold";
}
